import ComponentRepository from './architecture/componentRepository';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        'locomotive': () => import('./components/locomotive'),
        'page-transition': () => import('./components/page-transition'),
        'accordion': () => import('./components/accordion'),        
        'hero': () => import('./components/hero'),        
        'modal': () => import('./components/modal'),        
        'short-content': () => import('./components/short-content'),
        'navigation': () => import('./components/navigation'),
        'column-slider': () => import('./components/column-slider'),
        'article-page': () => import('./components/article-page'),
        'gallery-macro-slider': () => import('./components/gallery-macro-slider'),
        'sticky-element': () => import('./components/sticky-element'),
    });

    // When all components have been instantiated, reinit the locomotive to compensate for components tha affect the page height
    if (document.querySelector('[data-component="locomotive"]')) {
        window.cr.getGlobalInstance('locomotive')
            .then(locomotiveInstance => {
                locomotiveInstance.reinit();
            });
    }
});

// when all page content is loaded, reinit locomotive once agian, just to remove random locomotive page size bugs.
if (document.querySelector('[data-component="locomotive"]')) {
    window.addEventListener('load', (event) => {
        window.cr.getGlobalInstance('locomotive')
            .then(locomotiveInstance => {
                locomotiveInstance.reinit();
            });
    });
}
